<template>
  <n-layout>
    <layout-header/>
    <n-layout-content class="h-fit">
      <div class="grid grid-cols-24 gap-5 m-5 mx-10">
        <div class="col-span-16">
          <InfoCard class="h-96"/>
        </div>
        <div class="col-span-8 flex flex-col">
          <div class="flex flex-col gap-5 mt-1 w-1/2">
            <AreaCard
                :fetch-list="fetchAreas"
                :loading="areasLoading"
            />
            <CompanyCard
                :fetch-list="fetchCompanies"
                :loading="companiesLoading"
            />
          </div>
        </div>
        <!--        <div class="col-span-8">-->
        <!--          <div class="flex flex-row gap-5 m-auto mb-2">-->
        <!--            <AddButton/>-->
        <!--            <AssignButton :fetch-assignments="fetchAssignments"/>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="col-span-8">
          <div class="mb-3">
            <AddButton/>
          </div>
          <div class="mb-5 bg-primary rounded">
            <bound-conditions-list
                class="h-96"
            />
          </div>
        </div>
        <div class="col-span-16">
          <div class="mb-3 flex flex-row">
            <AssignButton :fetch-assignments="fetchAssignments"/>
            <div class="flex text-xl ml-auto">
              <div class="mr-3">Gesamtpreis:</div>
              <div
                  v-if="totalCosts && totalCostsConstraints"
                  class="flex mr-12"
              >
                <div v-if="totalCosts" class="mr-2">
                  {{
                    totalCosts.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                      maximumFractionDigits: 2
                    })
                  }}
                </div>
                <div
                    v-else
                    class="w-1/2"
                />
                <n-icon
                    v-if="calculateCostDifference(totalCosts, totalCostsConstraints) < 0"
                    size="28"
                    color="#CE332E"
                >
                  <ArrowUp/>
                </n-icon>
                <n-icon
                    v-if="calculateCostDifference(totalCosts, totalCostsConstraints) > 0"
                    size="28"
                    color="#00CFB4"
                >
                  <ArrowDown/>
                </n-icon>
                <n-tag :bordered="false" class="mx-1">
                  {{
                    (calculateCostDifference(totalCosts, totalCostsConstraints) < 0) ?
                        '+' : (calculateCostDifference(totalCosts, totalCostsConstraints) > 0) ? '-' : '+/-'
                  }}
                  {{ calculateCostDifferenceString(totalCosts, totalCostsConstraints) }}
                </n-tag>
                <n-icon
                    v-if="calculateCostDifference(totalCosts, totalCostsConstraints) < 0"
                    size="28"
                    color="#CE332E"
                >
                  <ArrowUp/>
                </n-icon>
                <n-icon
                    v-if="calculateCostDifference(totalCosts, totalCostsConstraints) > 0"
                    size="28"
                    color="#00CFB4"
                >
                  <ArrowDown/>
                </n-icon>
                <div v-if="totalCostsConstraints" class="ml-2 text-primary">
                  {{
                    totalCostsConstraints.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                      maximumFractionDigits: 2
                    })
                  }}
                </div>
              </div>
            </div>
          </div>

          <AreaAssignmentTable class="h-96" :assigned-data="assignData" :constrained-data="assignDataConstraints"
                               :loading="assignLoading || assignConstraintsLoading"/>
        </div>
      </div>
      <!--      <div class="flex flex-row gap-5 m-6">-->
      <!--        <n-card-->
      <!--          class="w-full"-->
      <!--          :title="t('components.utilizationChart.title')"-->
      <!--          header-style="margin: auto"-->
      <!--          :segmented="{ content: true, footer: 'soft' }"-->
      <!--        >-->
      <!--          <div-->
      <!--            v-if="assignError && !assignConstraintsLoading"-->
      <!--            class="flex justify-center items-center h-56 font-semibold text-primary"-->
      <!--          >-->
      <!--            {{ assignError }}-->
      <!--          </div>-->
      <!--          <chart-bar-->
      <!--            v-else-if="utilizationChartData.length > 0"-->
      <!--            class="h-56"-->
      <!--            :data="utilizationChartData"-->
      <!--            :y-min="100"-->
      <!--            :bar-colors="['#E74E0D', '#ffdcc7']"-->
      <!--            :bar-width="[38]"-->
      <!--            :bar-padding-outer="100"-->
      <!--            :tooltip-color="'#E74E0D'"-->
      <!--            :tooltip-format="val => {return val + ' %'}"-->
      <!--            hide-legend-->
      <!--          />-->
      <!--          <div-->
      <!--            v-else-->
      <!--            class="flex justify-center items-center h-56 font-semibold text-primary"-->
      <!--          >-->
      <!--            {{ t('common.info.noData') }}-->
      <!--          </div>-->
      <!--        </n-card>-->
      <!--      </div>-->
    </n-layout-content>
  </n-layout>
</template>

<script lang="ts" setup>
import {computed, onMounted, ref, watch} from 'vue'
import {ArrowDown, ArrowUp} from '@vicons/ionicons5'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import LayoutHeader from '@/layout/LayoutHeader.vue'
import type {Utilization} from '@/api/modules/assignments'
import ChartBar from '@/components/chart-bar/ChartBar.vue'
import type {Item} from '@/components/chart-bar/interface'
import BoundConditionsList from '@/components/bound-conditions/BoundConditionsList.vue'
import AreaAssingment from '@/components/assingments/AreaAssingment.vue'
import AreaCard from '@/components/areas/AreaCard.vue'
import CompanyCard from '@/components/companies/CompanyCard.vue'
import AddButton from "@/components/bound-conditions/AddButton.vue";
import AssignButton from "@/components/assingments/AssignButton.vue";
import AreaAssignmentTable from "@/components/assingments/AreaAssignmentTable.vue";
import InfoCard from "@/components/info/InfoCard.vue";

const {t} = useI18n()

const calculateCostDifference = ref((value1: number, value2: number) => {
  return value1 - value2
})

const calculateCostDifferenceString = ref((value1: number, value2: number) => {
  return `${(Math.abs((value1 - value2) / ((value1 + value2) / 2)) * 100).toFixed(1)} %`
})

const store = useStore()

const assignData = computed(() => store.getters['Assignments/getStandardAssignment'])
const assignDataConstraints = computed(() => store.getters['Assignments/getConstraintAssignment'])
const totalCosts = computed(() => store.getters['Assignments/getStandardTotalCosts'])
const totalCostsConstraints = computed(() => store.getters['Assignments/getConstraintTotalCosts'])
const assignError = computed(() => store.getters['Assignments/constraintAssignmentError'])

const mapChartData = (utilData: Array<Utilization>): Array<Item> => {
  const utilItemList: Item[] = []
  utilData.forEach((utilItem) => {
    utilItemList.push({
      label: `${store.getters['Companies/getCompanyName'](utilItem.company)}`,
      tooltip: +(utilItem.percent * 100).toFixed(0),
      values: {
        soll: utilItem.percent * 100,
        ist: 100 - (utilItem.percent * 100),
      },
    })
  })
  return utilItemList
}

const utilizationChartData = computed(() => mapChartData(store.getters['Assignments/getConstraintUtilization']))

const assignLoading = computed(() => store.getters['Assignments/standardAssignmentLoading'])
const assignConstraintsLoading = computed(() => store.getters['Assignments/constraintAssignmentLoading'])

const fetchAssignments = () => {
  const geoRestriction = store.getters['Settings/getGeoRestriction']
  store.dispatch('Assignments/FETCH_STANDARD_ASSIGNMENTS', geoRestriction)
  store.dispatch('Assignments/FETCH_CONSTRAINT_ASSIGNMENTS', geoRestriction)
}

const areasLoading = ref(false)

const fetchAreas = () => {
  store.dispatch('Areas/ADD_AREA_LIST')
}

const companiesLoading = ref(false)

const fetchCompanies = () => {
  store.dispatch('Companies/ADD_COMPANY_LIST')
}

watch(() => store.getters['Auth/isAuth'], (value) => {
  if (value) {
    fetchAreas()
    fetchCompanies()
  }
})

onMounted(() => {
  fetchAreas()
  fetchCompanies()
})
</script>

<style scoped>

</style>
