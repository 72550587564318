<template>
  <n-button
      type="primary"
      @click="handleAssign"
  >
    <template #icon>
      <n-icon color="#fff">
        <StartIcon/>
      </n-icon>
    </template>
    {{ t('components.boundConditions.button.startCalculation') }}
  </n-button>
</template>

<script setup lang="ts">
import {KeyboardDoubleArrowRightSharp as StartIcon} from '@vicons/material'
import type {PropType} from 'vue'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()

const props = defineProps({
  fetchAssignments: {
    type: Function as PropType<() => void>,
    required: true,
  },
})

const handleAssign = () => {
  props.fetchAssignments()
}
</script>


<style scoped>

</style>