<template>
  <div class="flex">
    <n-image
        class="rounded-l"
        width="380px"
        src="/construction_worker.jpg"
    />
    <n-card title="Auslastung / Infos">
      <div class="mb-1 text-justify">
        Eine optimale Zuordnung von Tiefbauunternehmen zu Ausbaugebieten hängt von zahlreichen Faktoren ab. Dazu
        zählen unter anderem die Preise der Tiefbauer für verschiedene Oberflächentypen, der Oberflächenmix des
        auszubauenden Gebiets sowie vertraglich festgelegte Obergrenzen für Ausbaukapazitäten und die Anzahl der zu
        vergebenden Gebiete.
      </div>
      <div class="mb-1 text-justify">
        Unser Demonstrator ermöglicht es, Auftragsvergaben automatisch basierend auf verschiedenen ausgewählten
        Bedingungen zu berechnen. Im Hintergrund ist der Demonstrator mit einer Datenbank verbunden, sodass die
        aktuellen Preise der Tiefbauer und die Eigenschaften der Gebiete stets in die Berechnung einfließen.
      </div>
      <div class="mb-1 text-justify">
        Um die aktuellen Datenbankeinträge zu sehen, klicken Sie auf die Schaltflächen
        <div class="font-bold inline">„Aufträge anzeigen“</div>
        und
        <div class="font-bold inline">„Tiefbauer anzeigen“</div> oben rechts. Mit der Schaltfläche
        <div class="font-bold inline">„Bedingungen hinzufügen“</div>
        können Sie Bedingungen zur
        Berechnung der optimalen Zuteilung der Tiefbauer zu den Aufträgen hinzufügen. Durch Klicken auf <div class="font-bold inline">„Berechnung
        starten“</div> wird der SAT-Solver im Hintergrund ausgeführt.
      </div>
      <div class="mb-1 text-justify">
        Die aktiven Bedingungen werden unten links angezeigt, und die Ergebnisse der Optimierung sehen Sie
        hervorgehoben in der rechten unteren Tabelle. Anhand der Gesamtsummen und des direkten prozentualen Vergleichs
        können Sie erkennen, welchen Einfluss die von Ihnen gewählten Bedingungen auf den Gesamtpreis haben.
      </div>
    </n-card>

  </div>

</template>

<script setup lang="ts">

</script>
<style scoped>

</style>