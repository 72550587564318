<template>
  <n-layout-header
    style="height: 64px; background-color: #EF7D27"
    bordered
  >
    <div class="flex items-center justify-between h-full mx-10">
      <div class="text-2xl text-white">
        {{ t('common.header.title') }}
      </div>
      <div class="flex items-center gap-4">
        <settings-menu :type="'country'" />
        <settings-menu :type="'year'" />
        <geo-restriction class="mt-1" />
        <database-refresh v-show="false" />
      </div>
    </div>
  </n-layout-header>
</template>

<script lang="ts" setup>
import {useI18n} from 'vue-i18n'
import DatabaseRefresh from '@/components/header/DatabaseRefresh.vue'
import SettingsMenu from '@/components/settings/SettingsMenu.vue'
import GeoRestriction from '@/components/header/GeoRestriction.vue'

const {t} = useI18n()
</script>

<style scoped>

</style>
