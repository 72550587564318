import type {DataTableColumn} from 'naive-ui'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import type {Assignment, AssignmentComparison} from '@/api/modules/assignments'

type Row = Assignment

export default () => {
  const store = useStore()

  const {t} = useI18n()

  const getColumns: () => Array<DataTableColumn<Row>> = () => {
    return [
      {
        title: t('components.areaAssignment.table.task'),
        key: 'area',
        width: 175,
        render: (assignment: Assignment) => {
          return store.getters['Areas/getAreaName'](assignment.area)
        },
        ellipsis: {
          tooltip: true,
        },
      },
      {
        title: t('components.areaAssignment.table.company'),
        key: 'company',
        width: 175,
        titleAlign: 'center',
        children: [
          {
            title: t('components.areaAssignment.table.withoutConstraints'),
            key: 'withoutConstraints',
            align: 'center',
            render: (assignment: AssignmentComparison) => {
              return store.getters['Companies/getCompanyName'](assignment.company.withoutConstraints)
            },
            ellipsis: {
              tooltip: true,
            },
          },
          {
            title: t('components.areaAssignment.table.withConstraints'),
            key: 'withConstraints',
            align: 'center',
            className: 'withConstraints',
            render: (assignment: AssignmentComparison) => {
              return store.getters['Companies/getCompanyName'](assignment.company.withConstraints)
            },
            ellipsis: {
              tooltip: true,
            },
          }
        ]
      },
      {
        title: t('components.areaAssignment.table.price'),
        key: 'price',
        width: 175,
        titleAlign: 'center',
        children: [
          {
            title: t('components.areaAssignment.table.withoutConstraints'),
            key: 'withoutConstraints',
            align: 'center',
            render: (assignment: AssignmentComparison) => {
              return assignment.price.withoutConstraints.toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits: 0,
              })
            },
            ellipsis: {
              tooltip: true,
            },
          },
          {
            title: t('components.areaAssignment.table.withConstraints'),
            key: 'withConstraints',
            align: 'center',
            className: 'withConstraints',
            render: (assignment: AssignmentComparison) => {
              return assignment.price.withConstraints.toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits: 0,
              })
            },
            ellipsis: {
              tooltip: true,
            },
          }
        ]
      },
    ]
  }

  return {
    getColumns,
  }
}
