<template>
  <n-button
      type="primary"
      @click="handleAdd"
  >
    <template #icon>
      <n-icon
          size="16"
          color="#fff"
      >
        <AddIcon/>
      </n-icon>
    </template>
    {{ t('components.boundConditions.button.add') }}
  </n-button>
</template>

<script setup lang="ts">
import type {NewBoundCondition} from "@/api/modules/boundConditions"
import api from "@/api"
import {Add16Filled as AddIcon} from '@vicons/fluent'
import AddConstraint from "@/components/bound-conditions/AddBoundCondition.vue"
import {ref, h} from "vue"
import {useDialog} from 'naive-ui'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()

const dialog = useDialog()

const addLoading = ref(false)

const store = useStore()

const fetchList = () => {
  store.dispatch('BoundConditions/SET_BOUND_CONDITION_LIST')
}

const handleAdd = () => {
  const add = (boundCondition: NewBoundCondition) => {
    addLoading.value = true
    api.boundConditions.createBoundCondition(boundCondition).then((res) => {
      fetchList()
      return res
    }).finally(() => {
      addLoading.value = false
      d.destroy()
    })
  }

  const d = dialog.info({
    title: 'Bedingung hinzufügen',
    content: () => {
      return h(AddConstraint, {
        addFunction: add,
        loadingValue: addLoading.value,
      })
    },
  })

  return d
}
</script>

<style scoped>

</style>