import type {AxiosPromise} from 'axios'
import type {Area} from '@/api/modules/areas'
import type {Company} from '@/api/modules/companies'
import axiosWithAuth from '@/api/axios'

export interface Assignment {
  area: Area['id']
  company: Company['id']
  price: number
}

export interface AssignmentComparison {
  area: Area['id']
  company: {
    withoutConstraints: Company['id']
    withConstraints: Company['id']
  }
  price: {
    withoutConstraints: number
    withConstraints: number
  }
}

export interface Utilization {
  company: number
  meters: number
  percent: number
}

export interface AssignmentResult {
  assignments: Array<Assignment>
  utilization: Array<Utilization>
  totalCosts: number
}

const assignments = {
  fetchAssignments: (useConstraints: boolean, geoRestrict?: boolean): AxiosPromise<AssignmentResult> => {
    return axiosWithAuth.get('/assign/', {params: {useConstraints, geoRestrict}})
  },
}

export default assignments
