<template>
  <n-card
      title="Aktive Bedingungen"
      header-style="margin: auto"
      :segmented="{ content: true, footer: 'soft' }"
  >
    <n-scrollbar
        trigger="none"
        style="max-height: 275px"
    >
      <n-data-table
          v-if="boundConditions.length && !listLoading"
          :columns="columns"
          :data="boundConditions"
          :bordered="false"
          :single-line="false"
          :bottom-bordered="false"
      />
      <div
          v-else-if="listLoading"
          class="flex h-full justify-center"
      >
        <n-spin/>
      </div>
      <div
          v-else
          class="flex h-full justify-center items-center font-semibold text-primary"
      >
        {{ t('components.boundConditions.info.noneActive') }}
      </div>
    </n-scrollbar>
  </n-card>
</template>

<script lang="ts" setup>
import {computed, onMounted, ref, watch} from 'vue'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'

import type {BoundCondition} from '@/api/modules/boundConditions'
import api from '@/api'
import useBoundConditions from '@/utils/composables/useBoundConditions'

const {t} = useI18n()

const store = useStore()

const listLoading = computed(() => store.getters['BoundConditions/boundConditionsLoading'])

const boundConditions = computed(() => store.getters['BoundConditions/getBoundConditions'])

const fetchList = () => {
  store.dispatch('BoundConditions/SET_BOUND_CONDITION_LIST')
}

const handleDelete = (id: BoundCondition['id']) => {
  store.dispatch('BoundConditions/DELETE_BOUND_CONDITION', id)
  api.boundConditions.deleteBoundCondition(id).then((res) => {
    fetchList()
    return res
  })
}

const {getColumns} = useBoundConditions()

const columns = getColumns(handleDelete)

// watch(() => store.getters['Auth/isAuth'], (value) => {
//   if (value)
//     fetchList()
// })

onMounted(() => {
  // if (store.getters['Auth/isAuth'])
  fetchList()
})
</script>

<style scoped>

</style>
