<template>
  <div class="flex">
    <n-card
        title="Zuordnung von Tiefbauern zu Gebieten"
        header-style="margin: auto"
        :segmented="{ content: true, footer: 'soft' }"
    >
      <div
          v-if="error && !loading"
          class="flex h-full justify-center items-center font-semibold text-primary"
      >
        {{ error }}
      </div>
      <div
          v-else-if="loading"
          class="flex h-full justify-center items-center w-full"
      >
        <n-spin/>
      </div>
      <n-data-table
          v-else
          :columns="columns"
          :data="data"
          :bordered="false"
          :max-height="220"
          :single-line="false"
          :bottom-bordered="false"
      >
        <template #empty>
          <div class="flex h-full justify-center items-center font-semibold text-primary">
            {{ t('common.info.noData') }}
          </div>
        </template>
      </n-data-table>
    </n-card>
  </div>
</template>


<script setup lang="ts">
import {onMounted, PropType} from 'vue'
import {computed, ref} from 'vue'
import {useI18n} from 'vue-i18n'
import {useStore} from 'vuex'
import type {Assignment, AssignmentComparison} from '@/api/modules/assignments'
import type {Area} from '@/api/modules/areas'
import useAreaAssignmentTable from '@/utils/composables/useAreaAssignmentTable'

const {t} = useI18n()

const props = defineProps({
  assignedData: {
    type: Array as PropType<Array<Assignment>>,
    default: () => [],
    required: true,
  },
  constrainedData: {
    type: Array as PropType<Array<Assignment>>,
    default: () => [],
    required: true,
  },
  highlight: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  loading: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

// const title = ref(props.withConstraints ? t('components.areaAssignment.titleConstraints') : t('components.areaAssignment.title'))

const {getColumns} = useAreaAssignmentTable()

const columns = getColumns()

const store = useStore()

// const error = computed(() => props.withConstraints ? store.getters['Assignments/constraintAssignmentError'] : store.getters['Assignments/standardAssignmentError'])
const error = computed(() => store.getters['Assignments/constraintAssignmentError'])

const data = computed(() => {
  let mergedData: AssignmentComparison[] = []
  props.assignedData.forEach((row: Assignment) => {
    let constrainedRow = props.constrainedData.find((constrainedRow: Assignment) => constrainedRow.area === row.area)
    mergedData.push({
      area: row.area,
      company: {
        withoutConstraints: row.company,
        withConstraints: constrainedRow?.company || ''
      },
      price: {
        withoutConstraints: row.price,
        withConstraints: constrainedRow?.price || 0
      }
    })
  })
  return mergedData
})

</script>


<style scoped>
:deep(.withConstraints) {
  //color: rgba(0, 128, 0, 0.75) !important;
  color: rgba(231, 78, 13, 1) !important;
}
</style>